.edit-study-modal {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    color: #202020;
    font-feature-settings: 'salt' on;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.2px;
    margin-bottom: 5px;
  }

  &__subtitle {
    color: var(--Fioletowy-1, #4f4294);
    font-feature-settings: 'salt' on;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.16px;
    margin-bottom: 50px;
  }

  .textarea {
    color: var(--Grafit, #202020);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 16px 20px;
    resize: vertical;
  }
  .textarea__wrapper {
    margin-bottom: 30px;
  }

  &__buttons {
    display: flex;
    gap: 20px;
    width: 100%;
    margin-top: 60px;
    .btn {
      width: 100%;
      height: 48px;
      font-feature-settings: 'ss03' on, 'ss02' on, 'salt' on;
      font-family: 'Source Sans Pro';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
