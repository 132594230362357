.login-page {

  &__forget-password {
    color: #4F4294;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.07px;
    text-decoration: none;
    margin-top: -20px;
    @include mobile-only {
      margin-top: 10px;
    }
  }
  &__email {
    margin-bottom: 0;
  }
  &__email-did-not-arrive {
    color: #333;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
  }
  &__error {
    background: #ffe1e1;
    border-radius: 4px;
    padding: 0 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: -0.01em;
    font-weight: 400;
    font-size: 14px;
    line-height: 126.9%;
    opacity: 0;
    transition: 0.3s;
    margin-top: 8px;
    margin-bottom: 20px;
    min-height: 56px;
    &--show {
      margin-top: 32px;
      opacity: 1;
      padding: 12px 19px;
    }
    span {
      color: #303030;
      font-family: Source Sans Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal; 
    }
  }
  &__acceptance{
    margin-top: 20px;
  }
}

