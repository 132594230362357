@import './components/DashboardTopBar.scss';
@import './components/DashboardSidebarMenu.scss';
@import './components/ChangePasswordModal.scss';
@import './components/AdministratorList.scss';
@import './components/MobileMenu.scss';

.dashboard {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
}
.dashboard-center {
  min-width: 100vw;
  height: calc(100vh - 50px);
  padding-left: 60px;
  @include mobile-only {
    padding-left: 0;
  }
}

.dashboard-content {
  height: 100%;
  width: 100%;
  padding: 90px 100px 40px 100px;
  @include mobile-only {
    padding: 74px 20px 16px 20px;
  }
}
.dashboard-heading {
  color: #202020;
  font-feature-settings: 'salt' on;
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.2px;
  display: flex;
  align-items: center;
}
.dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  .btn {
    max-width: 220px;
    width: 100%;
    min-height: 40px;
  }
}
.dashboard-heading-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  .svg--arrow-back {
    margin-right: 10px;
    path {
      stroke: #4f4294;
    }
  }
}
