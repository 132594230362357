.fullscreen-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f3f3f3;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  z-index: 99999999;
  align-items: flex-start;
  padding-top: 100px;
}

.fullscreen-modal {
  width: 100%;
  padding: 40px 20px;

  @media (min-width: 1000px) {
    padding: 0;
  }

  &__close {
    position: absolute;
    top: 40px;
    right: 20px;
    display: flex;
    cursor: pointer;
    flex-direction: row-reverse;
    max-width: 140px;
    justify-content: center;
    color: var(--Fioletowy-1, #4f4294);
    font-feature-settings: 'ss03' on, 'ss02' on, 'salt' on;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    min-width: 220px;
    height: 40px;
    border-radius: 4px;
    border: 2px solid var(--Fioletowy-1, #4f4294);
    background-color: transparent;
    align-items: center;
    @media (min-width: 1000px) {
      right: 150px;
    }
    .svg {
      margin-right: 0;
      width: 20px;
      path {
        stroke: #4f4294;
      }
    }
  }

  &__header {
    color: var(--Granatowy, #0e0136);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: -0.18px;
    margin-bottom: 20px;
  }

  &__subtitle {
    color: #4a445d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 156.25% */
    letter-spacing: -0.16px;
    margin-bottom: 50px;
  }
  .input {
    background-color: white;
  }

  &__input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    @media (min-width: 1000px) {
      gap: 20px;
      flex-direction: row;
    }
    .input__wrapper {
      width: 100%;
    }
  }

  &-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-height: calc(100vh - 100px);
    max-height: calc(100dvh - 100px);
    overflow: auto;
    padding-bottom: 54px;
  }
}
