.suspend-user-modal {
  &__descript {
    width: 100%;
    margin-top: 20px;
    color: #303030;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 50px;
    &--center{
      text-align: center;
      b{
        font-weight: 600;
      }
    }
  }
  &__buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
    @include mobile-only {
      margin-top: auto;
    }
    .btn {
      min-height: 50px;
      width: 100%;
    }
  }
}