.first-page-checkbox {
  margin-top: 20px;
  &__input {
    margin-left: 0;
  }
  &__option {
    display: flex;
    cursor: pointer;
  }
  .input__error {
    margin-top: 0;
  }
  &__title-wrapper {
    margin-left: 15px;
  }
}

.checkbox {
  input {
    display: none;
  }
  &:hover {
    cursor: pointer;
    .checkbox__dummy-input {
      border: 2px solid #4f4294;
    }
  }
  &__anchor {
    color: #4f4294;
    cursor: pointer;
    text-decoration: none;
  }
  &__label {
    display: block;
    gap: 14px;
    color: #3c3c3c;
    font-feature-settings: 'salt' on;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    a {
      color: #4f4294;
      cursor: pointer;
      font-weight: 600;
    }
  }

  &__label-wrapper {
    display: flex;
    gap: 14px;
  }
  &__dummy-input {
    width: 24px;
    height: 24px;
    display: flex;
    flex: 1;
    min-width: 24px;
    position: relative;
    border-radius: 6px;
    border: 2px solid var(--szary-300, #bfbfbf);
    transition: 0.3s;
    max-width: 24px;
    &--checked {
      background: #4f4294;
      border: 2px solid #4f4294;
    }
  }

  &__svg {
    opacity: 0;
    transition: 0.3s;
    height: 16px;
    width: 16px;
    margin-top: 2px;
    margin-left: 2px;
    path {
      stroke: white;
    }
    &--checked {
      opacity: 1;
    }
  }

  &__error {
    color: var(#e20d0d);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 2px;
    margin-left: 0;
    position: absolute;
  }
}
