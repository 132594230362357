.sort {
  display: flex;
  cursor: pointer;
  &__arrow {
    &--up {
      transform: rotate(180deg);
    }
    path {
      transition: 0.3s;
    }
    &--active {
      path {
        stroke: #202020;
      }
    }
  }
}
