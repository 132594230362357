@import './_reset';
@import './_variables';
@import './_fonts';
// @import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,900&display=swap');


.hidden {
  opacity: 0;
  max-height: 0;
  height: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
.tooltip {
  border-radius: 4px;
background: #FFF;
box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.02);
}
body {
  line-height: 1.5;
  font-size: 16px;
  font-family: $primaryFont;
}

#root {
  min-height: 100vh;
}
.page-wrapper {
  min-height: 100vh;
  &--width-center {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #FBFBFB;
  }
}

// .dificult.priority.importance .react-flow__handle {
//   display: none;
// }


.Toastify__toast-icon .svg {
  height: 24px;
  min-width: 24px;
}
.Toastify__toast {
  @include mobile-only {
    width: calc(100% - 32px);
    margin-left: 16px;
    border-radius: 8px !important;
  }
}
.Toastify__toast-container {
  z-index: 9999999999999999999999999999999999999999999999;
  @include mobile-only {
    bottom: 80px !important;
  }
}

