.login-content {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.04);
  min-width: 564px;
  margin-top: 52px;
  position: relative;
  padding: 40px 82px 60px 82px;
  max-width: 564px;
  margin-bottom: 52px;
  @include mobile-only {
    width: 100%;
    min-width: 100%;
    margin-top: 0;
    height: calc(100vh - 111px);
    display: flex;
    flex-direction: column;
    padding: 40px 20px 60px 20px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px -10px 30px 0px rgba(0, 0, 0, 0.06);
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  // &--only-mobile {
  //   display: none;
  //   @include mobile-only {
  //     display: flex;
  //     align-items: center;
  //     width: 100%;
  //     height: 111px;
  //     justify-content: center;
  //     color: var(--Fioletowy, #7919FF);
  //     font-feature-settings: 'salt' on;
  //     font-family: Source Sans Pro;
  //     font-size: 16px;
  //     font-style: normal;
  //     font-weight: 600;
  //     line-height: normal;
    // }
  // }
  &--goalmapp {
    display: flex;
    align-items: center;
    width: 100%;
    height: 111px;
    justify-content: center;
    margin-top: 71px;
    @include mobile-only {
      margin-top: 0;
      .svg {
        max-width: 138px;
        max-height: 52px;
      }
    }
  }
  &__children {
    display: flex;
    flex-direction: column;
    height: 100%;
    @include mobile-only {
      height: 100%;
      form {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
  &__wrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }
  &__logo-wrapper {
    position: absolute;
    top: -30px;
    right: -60px;
    @include mobile-only {
      display: none;
    }
  }
  &__button {
    display: flex;
    width: 100%;
    margin-top: 50px;
    .btn {
      width: 100%;
      min-height: 48px;
    }
    @include mobile-only {
      margin-top: auto;
    }
    &--activate {
      margin-top: 0;
      @include mobile-only {
        margin-top: 50px;
      }
    }
  }
  &__descript {
    max-width: 400px;
    text-align: center;
    color: #303030;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
    &--activate {
      max-width: 250px;
      margin-left: auto;
      margin-right: auto;
    }
    &--activate-link-expired {
      margin-bottom: 0;
    }
    @include mobile-only {
      margin: 0px auto;
    }
  }
}

.login-content-header {
  position: relative;
  text-align: center;
  color: #202020;
  font-feature-settings: 'salt' on;
  font-family: Source Sans Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.24px;
  padding: 0 20px;
  margin-bottom: 20px;
  &__back {
    position: absolute;
    top: 5px;
    left: 0;
    cursor: pointer;
  }
}
