.studies-list {
  @include mobile-only {
    padding-bottom: 10px;
  }
}
.tests-list {
  &__pagination {
    margin-bottom: 30px;
  }
  &__table {
    width: 100%;
    margin-top: 24px;
  }
  &__badge {
    display: flex;
  }
  &__table-list {
    border: 1px solid #e3e3e3;
    border-radius: 4px 4px 0 0;
    display: flex;
    &--status {
      width: 15% !important;
    }
    &--data {
      width: 15% !important;
    }
    &--number {
      width: 10% !important;
    }
  }
  &__thead {
    width: 100%;
    p {
      text-align: left;
      padding: 15px 0;
      background: #f1f1f1;
      color: #303030;
      font-feature-settings: 'salt' on;
      font-family: Source Sans Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.07px;
      width: 20%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 23px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 0px;
        height: 34px;
        border-left: 1px solid #cdcdcd;
      }
    }

    p:first-child {
      border-radius: 4px 0 0 0;
      padding-left: 29px;
      width: 30%;
    }
    p:nth-last-child(2) {
      &:after {
        display: none;
      }
    }
    p:last-child {
      border-radius: 0 4px 0 0;
      width: 60px;
      padding-right: 0;
      &:after {
        display: none;
      }
    }
  }
  &__tbody-list {
    border: 1px solid #e3e3e3;
    display: flex;
    border-bottom: unset;
    &:last-child {
      border-bottom: 1px solid #e3e3e3;
    }
  }
  &__tbody-item {
    padding: 15px 0;
    color: #303030;
    font-feature-settings: 'salt' on;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.07px;
    display: flex;
    width: 20%;
    padding-left: 5px;
    &--badge {
      width: 15%;
    }
    &--data {
      width: 15%;
    }
    &--short {
      p {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        // overflow: hidden;
        // text-overflow: ellipsis;
        text-overflow: ellipsis;
        line-height: 22px;
        max-height: 22px;
        word-break: break-all;
        line-break: loose;
        overflow: hidden;
        padding-right: 10px;
      }
    }

    &:first-child {
      padding-left: 29px;
      width: 30%;
    }
    &:last-child {
      cursor: pointer;
      padding-right: 20px;
      width: 60px;
      display: flex;
      justify-content: flex-end;
    }

    &--number {
      width: 10%;
    }
  }

}

.test-mobile-list {
  margin-top: 20px;
  &__item {
    display: flex;
    flex-direction: column;
    border: 1px solid #e3e3e3;
    padding: 14px 16px;
  }
  &__item-top {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    cursor: pointer;
    position: relative;
  }
  &__item-onClick {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 50px);
    height: 100%;
  }
  &__item-name {
    display: flex;
    align-items: center;
    max-width: 250px;
    width: 100%;
  }
  &__item-bottom {
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
    .btn {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;
      svg path {
        stroke: #4f4294;
      }
    }
    &--show {
      margin-top: 25px;
      max-height: 150px;
      transition: 0.3s;
    }
    &--link {
      color: #303030;
      font-family: Source Sans Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.07px;
      text-decoration-line: underline;
    }
    &--numbers {
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
      padding-right: 40px;
      div {
        display: flex;
        margin-right: 10px;
      }
      p {
        color: #303030;
        font-family: Source Sans Pro;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.07px;
      }
      span {
        color: #303030;
        font-family: Source Sans Pro;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.07px;
        margin-left: 5px;
      }
    }
    &--data {
      display: flex;
      p {
        color: #303030;
        font-family: Source Sans Pro;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.07px;
      }
      span {
        margin-left: 5px;
        color: #303030;
        font-family: Source Sans Pro;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.07px;
      }
    }
  }
  &__item-dots {
    position: relative;
  }
  .badge {
    min-width: 160px;
    text-align: center;
    @include mobile-only {
      min-width: unset;
    }
  }
}

.tests-list__tbody-item {
  &--copy-icon {
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .svg {
      pointer-events: none;
    }
  }
  &--copy-element {
    display: flex;
    justify-content: space-between;
    &:hover {
      .tests-list__tbody-item--copy-icon {
        display: flex;
      }
    }
  }
}
